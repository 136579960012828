<template>
  <div class="faq-item">
    <div :class="active ? 'faq-item__bar is-dropped': 'faq-item__bar'" @click="toggleAccordion">
      <h3 class="bar-text">{{ faq.question }}</h3>
      <svg
        class="down-icon"
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="10.924"
        viewBox="0 0 18 10.924"
      >
        <path
          d="M13.957,134.128l-8.011-8.011a.989.989,0,0,1,0-1.4l.934-.934a.989.989,0,0,1,1.4,0l6.379,6.349,6.379-6.349a.989.989,0,0,1,1.4,0l.934.934a.989.989,0,0,1,0,1.4l-8.011,8.011a.989.989,0,0,1-1.4,0Z"
          transform="translate(-5.656 -123.495)"
          fill="#878787"
        />
      </svg>
    </div>
    <div class="faq-item__dropdown" v-html="'<p>'+faq.answer+'</p>'">
    </div>
  </div>
</template>

<script>
import { helpers } from '../helper/portal_config';

export default {
  props: ['faq'],
  data() {
    return {
      active: false,
      helpers: ""
    };
  },
  methods: {
    toggleAccordion() {
      this.active = !this.active;
    },
  },
  created() {
      this.helpers = helpers;
  },
};
</script>

<style>
  .faq-item__bar.is-dropped + .faq-item__dropdown {
    display: block;
  }
</style>
