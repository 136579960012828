<template>
  <div>
    <div class="page-banner">
      <h1 v-html="pageTitle" :class="[this.currentLanguage == 'AR' ? 'rtlText': '']"></h1>
    </div>
    <div class="page-content">
      <div class="page-content__inner">
        <div class="contacts">
          <p class="contacts__intro" :class="[this.currentLanguage == 'AR' ? 'rtlText': '']">{{ $t('contact.title') }}</p>
          <div class="contacts__list" :class="[this.currentLanguage == 'AR' ? 'rtlText': '']">
            <div class="list-item" v-for="contact in contactInfos" :key="contact.order">
              <p>{{ contact.content | separationLabel }}</p>
              <span>{{ contact.content | separationText }}</span>
            </div>
          </div>
          <div class="contacts__intro" :class="[this.currentLanguage == 'AR' ? 'rtlText': '']" v-if="$t('contact.afterList') != 'contact.afterList'" v-html="$t('contact.afterList')">
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { helpers } from '../helper/portal_config';

const apiMethod = 'getPortalContact';

export default {
  name: 'ContactUs',
  title: `${helpers.portal_name} Contact`,
  data() {
    return {
      pageTitle: this.$i18n.t('contactus'),
      contactInfos: [],
      currentLanguage: "",
      currentCountry: "",
    };
  },
  methods: {
    setCurrentCountry() {
      const { host } = window.location;
      this.currentCountry = host.split('.')[0].slice(0,2).toUpperCase();
    },
    setCurrentLanguage() {
      var lang = "";
      const { host } = window.location;
      var country = host.split('.')[0].slice(0,2).toUpperCase();
      if (helpers.countryDefaultLanguage.hasOwnProperty(country)) {
        lang = helpers.countryDefaultLanguage[country].toUpperCase();
      } else {
        lang = country.toUpperCase();
      }
      this.currentLanguage = lang;
    },
  },
  filters: {
    separationLabel(value) {
      if (!value) return '';
      value = value.split(':');
      return value[0];
    },
    separationText(value) {
      if (!value) return '';
      value = value.split(':');
      return value[1];
    },
  },
  mounted() {
    this.setCurrentLanguage();
    this.setCurrentCountry();

    axios
      .get(
        `https://api.hubdelivery.io/store/${apiMethod}?portal_name=${helpers.portal_name}&country=${this.currentCountry}&language=${this.currentLanguage}`,
      )
      .then((response) => {
        if (response.data.treatment === 1) {
          this.contactInfos = response.data.contact;
        }
      });
  },
};
</script>
