import Vue from 'vue';
import VueRouter from 'vue-router';
import Portal from '../views/Portal.vue';
import Terms from '../views/Terms.vue';
import Home from '../views/Home.vue';
import Privacy from '../views/Privacy.vue';
import CookiesPolicy from '../views/CookiesPolicy.vue';
import Impressum from '../views/Impressum.vue';
import TermsOfUse from '../views/TermsOfUse.vue';
import Unsubscription from '../views/Unsubscription.vue';
import Faq from '../views/Faq.vue';
import Contact from '../views/Contact.vue';
import NotFound from '../views/NotFound.vue';
import ProductDetails from '../views/ProductDetails.vue';
import ProductCategory from '../views/ProductCategory.vue';
import { i18n } from '../plugins/i18n.js';
import { helpers } from '../helper/portal_config';

//import LandingStep1 from '../views/LandingPage.vue';
//import LandingStep2 from '../views/LandingPage2.vue';
//import LandingStep3 from '../views/LandingPage3.vue';

import SearchResult from '../views/SearchResult.vue';

//import Landing from '../views/Landing.vue';
//import LandingContact from '../views/landing/LandingContact.vue';
//import LandingFaq from '../views/landing/LandingFaq.vue';
//import LandingPrivacy from '../views/landing/LandingPrivacy.vue';
//import LandingTerms from '../views/landing/LandingTerms.vue';
//import LandingUnsubscription from '../views/landing/LandingUnsubscription.vue';

import DoLogin from '../components/DoLogin.vue';

// testing goal
import ProductDetailsVideo from '../views/ProductDetailsVideo.vue';

Vue.use(VueRouter);

const routes = [{
        path: '*',
        component: NotFound,
    },
    {
        path: '/dologin/:accesscode',
        component: DoLogin,
    },
    {
        path: '/',
        component: Portal,
        children: [{
                path: '',
                name: 'Home',
                component: Home,
            },
            {
                path: '/product-type/:name?/:id?',
                name: 'ProductType',
                component: Home,
            },
            {
                path: '/terms/:termsversion?/',
                name: 'Terms',
                component: Terms,
            },
            {
                path: '/privacy',
                name: 'Privacy',
                component: Privacy,
            },
            {
                path: '/cookies-policy',
                name: 'CookiesPolicy',
                component: CookiesPolicy,
            },
            {
                path: '/impressum',
                name: 'Impressum',
                component: Impressum,
            },
            {
                path: '/terms-of-use',
                name: 'TermsOfUse',
                component: TermsOfUse,
            },
            {
                path: '/unsubscription',
                name: 'unsubscription',
                component: Unsubscription,
            },
            {
                path: '/faq',
                name: 'Faq',
                component: Faq,
            },
            {
                path: '/contact',
                name: 'Contact',
                component: Contact,
            },
            {
                path: '/details/:type/:productId',
                name: 'ProductDetails',
                component: ProductDetails,
                meta: {
                    requiresAccessCode: true,
                },
            },
            {
                path: '/categories/:type/:title/:id',
                name: 'ProductCategory',
                component: ProductCategory,
            },
            {
                path: '/search/:word?/',
                name: 'SearchResult',
                component: SearchResult,
            },
            {
                // for testing goal
                path: '/detailsVideo/',
                name: 'ProductDetailsVideo',
                component: ProductDetailsVideo,
            },
        ],
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve({ x: 0, y: 0 });
            }, 400);
        });
    },
});

router.beforeEach((to, from, next) => {
    const { host } = window.location;
    const parts = host.split('.');
    const lang = parts[0].slice(0, 2);

    if (lang && lang.length === 2) {
        // set locale
        i18n.locale = lang;
        
        // get default language if it is set
        if (helpers.countryDefaultLanguage.hasOwnProperty(lang.toUpperCase())) {
            i18n.locale = helpers.countryDefaultLanguage[lang.toUpperCase()].toLowerCase();
        }
    }
    if (to.matched.some((record) => record.meta.requiresAccessCode)) {
        if (localStorage.getItem('accessCode') == null) {
            if (from.name == 'ProductType') {
                next({
                    path: from.path || '/',
                });
            } else {
                next({
                    path: '/',
                });
            }
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
