<template>
  <div>
    <div class="hero" v-if="hasBannerFlag">
        <img src="../assets/images/hero-img.jpg" alt="welcome our page">

        <div class="hero__content">
            <div class="hero__content--bottom">
                <ul>
                    <li>
                        <div class="list-image">
                            <img src="../assets/images/icons/home_icon_1.png" :alt="$t('home.banner.title1')">
                        </div>
                        <div class="list-data">
                            <h2>{{ $t("home.banner.title1") }}</h2>
                            <p>{{ $t("home.banner.description1") }}</p>
                        </div>
                    </li>
                     <li>
                        <div class="list-image">
                            <img src="../assets/images/icons/home_icon_2.png" :alt="$t('home.banner.title2')">
                        </div>
                        <div class="list-data">
                            <h2>{{ $t("home.banner.title2") }}</h2>
                            <p>{{ $t("home.banner.description2") }}</p>
                        </div>
                    </li>
                    <li>
                        <div class="list-image">
                            <img src="../assets/images/icons/home_icon_3.png" :alt="$t('home.banner.title3')">
                        </div>
                         <div class="list-data">
                            <h2>{{ $t("home.banner.title3") }}</h2>
                            <p>{{ $t("home.banner.description3") }}</p>
                        </div>
                    </li>
                    <li>
                        <div class="list-image">
                            <img src="../assets/images/icons/home_icon_4.png" :alt="$t('home.banner.title4')">
                        </div>
                        <div class="list-data">
                            <h2>{{ $t("home.banner.title4") }}</h2>
                            <p>{{ $t("home.banner.description4") }}</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div
      class="products"
      v-for="category in allCategories"
      :key="category.order"
    >
      <section class="section--products">
        <!-- if design type A, B, C, D -->
        <CarouselA
          v-if="category.design == 'A'"
          :products="category.products"
          :title="category.fullname"
          :categoryid="category.name"
        />
        <CarouselB
          v-if="category.design == 'B'"
          :products="category.products"
          :title="category.fullname"
          :categoryid="category.name"
        />
        <CarouselC
          v-if="category.design == 'C'"
          :products="category.products"
          :title="category.fullname"
          :categoryid="category.name"
        />
        <CarouselD
          v-if="category.design == 'D'"
          :products="category.products"
          :title="category.fullname"
          :categoryid="category.name"
        />
      </section>
    </div>

    <section class="section--our-categories">
      <div class="section__inner">
        <div class="our-categories">
          <div class="our-categories__head">
            <h3>{{ $t("home.ourcategories.title") }}</h3>
          </div>

          <div class="our-categories__body">
            <ul class="our-categories__list">
              <li v-for="category in categories" :key="category.id" v-if="category.order != null">
                <router-link :to="{
                    name: 'ProductCategory',
                    params: {
                      type: ($route.params.id || '1'),
                      title: category.item,
                      id: category.id
                    },
                }">
                    <div class="categories_link_item">
                      <span>{{ category.item }}</span>
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10.934"
                          height="18.018"
                          viewBox="0 0 10.934 18.018"
                      >
                          <path
                          d="M38.139,47.366,30.12,55.385a.99.99,0,0,1-1.4,0l-.935-.935a.99.99,0,0,1,0-1.4l6.355-6.385-6.355-6.385a.99.99,0,0,1,0-1.4l.935-.935a.99.99,0,0,1,1.4,0l8.019,8.019A.99.99,0,0,1,38.139,47.366Z"
                          transform="translate(-27.494 -37.657)"
                          />
                      </svg>
                    </div>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <cookie-law theme="portal" :buttonText="$t('cookie.btn')">
      <div slot="message">
        {{ $t("cookie.message") }}
        <router-link to="cookies-policy">{{ $t("cookie.link") }}</router-link>
      </div>
    </cookie-law>
  </div>
</template>

<script>
import CookieLaw from 'vue-cookie-law';
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import { helpers } from '../helper/portal_config';
import EventBus from '../helper/event_bus';

import CarouselA from '../components/CarouselA';
import CarouselB from '../components/CarouselB';
import CarouselC from '../components/CarouselC';
import CarouselD from '../components/CarouselD';

// api to get products
const apiPrTypeMethod = 'getProductListByType';

export default {
  name: 'Home',
  title: helpers.portal_name,
  data() {
    return {
      helpers: '',
      categories: [],
      allCategories: [],
      hasBannerFlag: null,
      currentLanguage: "",
      currentCountry: ""
    };
  },
  components: {
    CarouselA,
    CarouselB,
    CarouselC,
    CarouselD,
    CookieLaw
  },
  methods: {
    checkIsLogged() {
      // if you are not login than showed modal
      if (!this.isLoggedIn) {
        EventBus.$emit('SHOW_SIGNIN_MODAL');
      }
    },
    setProducts(types) {
      const keys = Object.keys(types);
      keys.forEach((element, index) => {
        types[element].fullname = element;
        this.allCategories.push(types[element]);
      });
      // filtered only homepage
      const categoriesFiltered = this.allCategories.filter((item) => item.homepage > 0);
      // sorting homepage categories
      this.sortProducts(categoriesFiltered);
    },
    sortProducts(filteredProducts) {
      filteredProducts.sort((a, b) => a.homepage - b.homepage);
      filteredProducts = this.slicesProducts(filteredProducts);
      this.allCategories = filteredProducts;
    },
    slicesProducts(products) {
      return products.map((item) => {
        item.products = item.products.slice(0, item.max_products);
        return item;
      });
    },
    sortCategories(categoriesLocal) {
      categoriesLocal.sort((a, b) => a.order - b.order);
      this.categories = categoriesLocal;
    },
    hasBanner() {
        let banner = document.querySelector('.page-header__menu li.is-active a');
        let type = banner.getAttribute('href').substr(banner.getAttribute('href').length-1, banner.getAttribute('href').length);
        console.log( type);
        return helpers.menu.some(function(item) {
            return (item.type == type && item.isActive == true && item.banner == 1);
        });
    },
    setCurrentCountry() {
      const { host } = window.location;
      this.currentCountry = host.split('.')[0].slice(0,2).toUpperCase();
    },
    setCurrentLanguage() {
      var lang = "";
      const { host } = window.location;
      var country = host.split('.')[0].slice(0,2).toUpperCase();
      if (helpers.countryDefaultLanguage.hasOwnProperty(country)) {
        lang = helpers.countryDefaultLanguage[country].toUpperCase();
      } else {
        lang = country.toUpperCase();
      }
      this.currentLanguage = lang;
    },
  },
  computed: {
    ...mapGetters({
      accessCode: 'globals/accessCode',
      isLoggedIn: 'globals/isLoggedIn',
    }),
  },
  watch: {
    $route(to, from) {
      this.allCategories = [];
      this.categories = [];

      // check user was logged and send access_code as a parameter.
        let apiUrl = null;
        if (this.isLoggedIn) {
            apiUrl = `https://api.hubdelivery.io/store/${apiPrTypeMethod}?portal_name=${helpers.portal_name
            }&country=${this.currentCountry}&language=${this.currentLanguage}&product_type=${this.$route.params.id || helpers.portal_type
            }&access_code=${this.accessCode}`;
        } else {
            apiUrl = `https://api.hubdelivery.io/store/${apiPrTypeMethod}?portal_name=${helpers.portal_name
            }&country=${this.currentCountry}&language=${this.currentLanguage}&product_type=${this.$route.params.id || helpers.portal_type
            }`;
        }

      axios
        .get(
            apiUrl
        )
        .then((response) => {
            this.hasBannerFlag = this.hasBanner();

          if (response.data.treatment === 1) {
            const { categories } = response.data;
            const types = categories;
            this.setProducts(types);

            // upload categories
            const categoriesKeys = Object.keys(categories);
            const categoriesLocal = [];
            categoriesKeys.forEach((element, index) => {
              const obj = {
                id: types[element].name,
                item: element,
                order: types[element].order,
              };
              categoriesLocal.push(obj);
            });
            this.sortCategories(categoriesLocal);
          }
        });
    },
  },
  created() {
    this.helpers = helpers;

    this.setCurrentLanguage();
    this.setCurrentCountry();

    // check user was logged and send access_code as a parameter.
       let apiUrl = null;
        if (this.isLoggedIn) {
            apiUrl = `https://api.hubdelivery.io/store/${apiPrTypeMethod}?portal_name=${helpers.portal_name
            }&country=${this.currentCountry}&language=${this.currentLanguage}&product_type=${this.$route.params.id || helpers.portal_type
            }&access_code=${this.accessCode}`;
        } else {
            apiUrl = `https://api.hubdelivery.io/store/${apiPrTypeMethod}?portal_name=${helpers.portal_name
            }&country=${this.currentCountry}&language=${this.currentLanguage}&product_type=${this.$route.params.id || helpers.portal_type
            }`;
        }

    axios
      .get(
        apiUrl
      )
      .then((response) => {
        if (response.data.treatment === 1) {
          const { categories } = response.data;
          const types = categories;
          this.setProducts(types);

          // upload categories
          const categoriesKeys = Object.keys(categories);
          const categoriesLocal = [];
          categoriesKeys.forEach((element, index) => {
            const obj = {
              id: types[element].name,
              item: element,
              order: types[element].order,
            };
            categoriesLocal.push(obj);
          });
          this.sortCategories(categoriesLocal);
        }
      });
  },
  mounted() {
    this.hasBannerFlag = this.hasBanner();
  },
};
</script>

<style>
.Cookie--portal {
    background: #000;
    padding: 8px 25px;
    box-shadow: 0 -10px 25px rgba(0, 0, 0, 0.1);
}

.Cookie--portal {
    color: #fff;
    font-size: 14px;
}

.Cookie--portal a {
    color: #fff;
    font-size: 14px;
    text-decoration: underline;
}

.Cookie--portal a:hover {
    color: #ffdd3d;
}

.Cookie--portal .Cookie__button {
  background: #222;
  color: #ffdd3d;
  border-radius: 5px;
  padding: 10px 35px;
}

.Cookie--portal .Cookie__button:hover {
  background: #ffdd3d;
  color: #222;
}

@media (max-width: 800px) {
    .Cookie--portal .Cookie__button {
        margin-bottom: 0;
    }

    .Cookie--portal .Cookie__content {
        margin: 0;
    }

    .Cookie--portal .Cookie__button {
        font-size: 12px;
    }

    .Cookie--portal .Cookie__buttons {
        margin-top: 5px;
    }
}
</style>
