<template>
  <div class="c-stars">
    <star-rating
      v-model="rating"
      :star-size="30"
      @rating-selected="changeRating"
      :show-rating="false"
      />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import { helpers } from "../helper/portal_config";
import StarRating from "vue-star-rating";

export default {
  name: "Stars",
  data() {
    return {
      rating: 0,
      wasRating: false,
      error: "",
      currentLanguage: "",
      currentCountry: ""
    };
  },
   props: {
    product: Object,
  },
  components: {
    StarRating,
  },
  computed: {
    ...mapGetters({
      accessCode: "globals/accessCode",
    }),
  },
  methods: {
    setRating(rating) {
      if (!this.wasRating) {
        this.rating = rating;
      }
    },
    changeRating(rating) {
        this.rating = rating;
        this.sendRating();
    },
    sendRating() {
      axios
        .post(
          `${helpers.ratingsAPI}?portal_name=${
            helpers.portal_name
          }&country=${this.currentCountry}&language=${this.currentLanguage}&access_code=${
            this.accessCode
          }&product_id=${this.product.product_id}&note=${this.rating}`
        )
        .then((response) => {
          if (response.data.treatment === 1) {
            this.wasRating = true;
          }
        });
    },
    setCurrentCountry() {
      const { host } = window.location;
      this.currentCountry = host.split('.')[0].slice(0,2).toUpperCase();
    },
    setCurrentLanguage() {
      var lang = "";
      const { host } = window.location;
      var country = host.split('.')[0].slice(0,2).toUpperCase();
      if (helpers.countryDefaultLanguage.hasOwnProperty(country)) {
        lang = helpers.countryDefaultLanguage[country].toUpperCase();
      } else {
        lang = country.toUpperCase();
      }
      this.currentLanguage = lang;
    },
  },
  created() {
    this.setCurrentLanguage();
    this.setCurrentCountry();
     
    if (this.product != null) {
        this.setRating(this.product.rating);
    }
  }
};
</script>

<style scoped>
.c-stars {
}
.c-stars__message {
  margin-left: 15px;
}
</style>
