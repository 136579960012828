var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[this.currentLanguage == 'AR' ? 'rtlText': '']},[_c('div',{staticClass:"product-entry product-entry--video-type"},[_c('div',{staticClass:"product-entry__content"},[_c('div',{staticClass:"video-type__item"},[_c('div',{staticClass:"img-wrapper"},[_c('a',{staticClass:"play-btn",attrs:{"href":_vm.product.url},on:{"click":function($event){$event.preventDefault();_vm.index = 0}}},[_c('svg',{attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","width":"32","height":"32","viewBox":"0 0 32 32"}},[_c('path',{attrs:{"d":"M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16-7.163-16-16-16zM16 29c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13zM12 9l12 7-12 7z"}})])]),(_vm.product.type != 5)?_c('div',[_c('img',{attrs:{"src":_vm.product.icon_small,"alt":_vm.product.title}})]):_c('div',[_c('img',{staticClass:"video-thumb",attrs:{"src":_vm.product.icon_small,"alt":_vm.product.title}})])]),_c('div',{staticClass:"item-data"},[_c('div',{staticClass:"headline"},[_c('h2',[_vm._v(_vm._s(_vm.product.title))]),(_vm.helpers.hasStars == 1)?_c('Stars',{attrs:{"product":_vm.product}}):_vm._e()],1),(_vm.helpers.hasFavourite == 1)?_c('Favourite',{attrs:{"product":_vm.product}}):_vm._e(),_c('a',{staticClass:"product-entry__action-btn",class:[this.currentLanguage == 'AR' ? 'rtlText': ''],on:{"click":function($event){_vm.index = 0}}},[(_vm.product.type === 1)?_c('span',[_vm._v(_vm._s(_vm.$t("button.playGame")))]):(_vm.product.type === 5)?_c('span',[_vm._v(_vm._s(_vm.$t("button.playVideo")))]):_c('span',[_vm._v(_vm._s(_vm.$t("button.download")))])])],1)])])]),_c('CoolLightBox',{attrs:{"items":[
      {
        title: _vm.product.title,
        description: _vm.product.description_short,
        src: _vm.product.url,
      },
    ],"index":_vm.index},on:{"close":function($event){_vm.index = null}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }