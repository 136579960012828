<template>
  <div :class="[this.currentLanguage == 'AR' ? 'rtlText': '']">
    <Header />
    <transition name="fade" mode="out-in">
        <router-view></router-view>
     </transition>
    <Footer :color="helpers.accent_color" />
  </div>
</template>

<script>
import Header from '../components/Header.vue';
import Footer from '../components/Footer.vue';
import { helpers } from '../helper/portal_config';

export default {
  name: 'Portal',
  title: helpers.portal_name,
  components: {
    Header,
    Footer,
  },
  created() {
      this.helpers = helpers;
  }
};
</script>

<style>
.fade-enter,
.fade-leave-to,
.fade-leave-active {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.Cookie--portal {
  background: #000;
  padding: 8px 25px;
  box-shadow: 0 -10px 25px rgba(0, 0, 0, 0.1);
}

.Cookie--portal {
  color: #fff;
  font-size: 14px;
}

.Cookie--portal a {
  color: #fff;
  font-size: 14px;
  text-decoration: underline;
}

.Cookie--portal a:hover {
  color: #ffdd3d;
}

.Cookie--portal .Cookie__button {
  background: #222;
  color: #ffdd3d;
  border-radius: 5px;
  padding: 10px 35px;
}

.Cookie--portal .Cookie__button:hover {
  background: #ffdd3d;
  color: #222;
}

@media (max-width: 667px) {
  .Cookie--portal .Cookie__button {
    margin-bottom: 0;
  }

  .Cookie--portal .Cookie__content {
    margin: 0;
  }

  .Cookie--portal .Cookie__button {
    font-size: 12px;
  }

  .Cookie--portal .Cookie__buttons {
    margin-top: 5px;
  }
}
</style>
