<template>
  <div class="c-favourite">
    <div v-if="isFavorite">
      <button class="c-favourite__btn" @click="sendFavorite()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            fill="red"
            d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"
          />
        </svg>
      </button>
    </div>
    <div v-else>
      <button class="c-favourite__btn" @click="sendFavorite()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            d="M458.4 64.3C400.6 15.7 311.3 23 256 79.3 200.7 23 111.4 15.6 53.6 64.3-21.6 127.6-10.6 230.8 43 285.5l175.4 178.7c10 10.2 23.4 15.9 37.6 15.9 14.3 0 27.6-5.6 37.6-15.8L469 285.6c53.5-54.7 64.7-157.9-10.6-221.3zm-23.6 187.5L259.4 430.5c-2.4 2.4-4.4 2.4-6.8 0L77.2 251.8c-36.5-37.2-43.9-107.6 7.3-150.7 38.9-32.7 98.9-27.8 136.5 10.5l35 35.7 35-35.7c37.8-38.5 97.8-43.2 136.5-10.6 51.1 43.1 43.5 113.9 7.3 150.8z"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import { helpers } from "../helper/portal_config";

export default {
  name: "Favourite",
  data() {
    return {
      error: "",
      favActive: false,
      currentLanguage: "",
      currentCountry: "",
    };
  },
  props: {
    product: Object,
  },
  computed: {
    ...mapGetters({
      accessCode: "globals/accessCode",
    }),
    isFavorite() {
      return this.favActive;
    },
  },
  methods: {
    sendFavorite() {
      this.favActive = !this.favActive;
      // calculate to remove or add it to favourite
      let favApi = this.favActive == true ? helpers.favouriteAPI : helpers.favouriteRemoveAPI;
      console.log(favApi);
      axios
        .post(
          `${favApi}?portal_name=${
            helpers.portal_name
          }&country=${this.currentCountry}&language=${this.currentLanguage}&access_code=${
            this.accessCode
          }&product_id=${this.product.product_id}`
        )
        .then((response) => {
          console.log(response);
          if (response.data.treatment === 1) {
            console.log("added/removed favorite");
          }
        });
    },
    setCurrentCountry() {
      const { host } = window.location;
      this.currentCountry = host.split('.')[0].slice(0,2).toUpperCase();
    },
    setCurrentLanguage() {
      var lang = "";
      const { host } = window.location;
      var country = host.split('.')[0].slice(0,2).toUpperCase();
      if (helpers.countryDefaultLanguage.hasOwnProperty(country)) {
        lang = helpers.countryDefaultLanguage[country].toUpperCase();
      } else {
        lang = country.toUpperCase();
      }
      this.currentLanguage = lang;
    },
  },
  created() {
    this.setCurrentLanguage();
    this.setCurrentCountry();
    if (this.product != null) {
        if (this.product.favourite == 1) {
            this.favActive = true;
        } else {
            this.favActive = false;
        }
    }
  },
};
</script>

<style scoped>
.c-favourite {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.c-favourite__btn {
  display: flex;
  align-items: center;
  border: none;
  background: none;
}
.c-favourite__btn svg {
  display: block;
  width: 30px;
  height: 30px;
}
.fav-message {
  font-weight: 600;
  margin-bottom: 0;
  margin-left: 10px;
}
</style>
