<template>
  <div class="section__inner section__inner--noRightpad">
    <div class="section-head">
      <h2 class="section-head__title" :class="[this.currentLanguage == 'AR' ? 'rtlText': '']">
        {{ $t("product.relateditems.title") }}
      </h2>
    </div>

    <div class="section-body">
      <swiper
        class="swiper carousel-wrapper carousel-wrapper--smpad"
        :options="swiperOption"
        :auto-update="true"
        :auto-destroy="false"
        :delete-instance-on-destroy="true"
        :cleanup-styles-on-destroy="true"
      >
        <swiper-slide
          :class="
            isBookType
              ? 'carousel-item card--v4 card--ebook'
              : 'carousel-item card--v4'
          "
          v-for="ritem in products"
          :key="ritem.product_id"
        >
          <div class="item-img">
            <router-link
              :to="{
                name: 'ProductDetails',
                params: { productId: ritem.product_id, type: helpers.portal_name  },
              }"
            >
              <img :src="ritem.icon_small" alt="Product Name" />
            </router-link>
          </div>
          <div class="item-data">
            <div class="data-value">
              <router-link
                :to="{
                  name: 'ProductDetails',
                  params: { productId: ritem.product_id, type: helpers.portal_name },
                }"
              >
                <h3>{{ ritem.title }}</h3>
              </router-link>
            </div>
            <router-link
                :to="{
                  name: 'ProductDetails',
                  params: {
                      productId: ritem.product_id,
                      type: helpers.portal_name
                      },
                }"
              >
                <div class="btn--primary btn--sm" :class="[this.currentLanguage == 'AR' ? 'rtlText': '']">
                  <span v-if="ritem.type === 1">{{ $t("button.playGame") }}</span>
                  <span v-else-if="ritem.type === 5">{{ $t("button.playVideo") }}</span>
                  <span v-else>{{ $t("button.download") }}</span>
                </div>
            </router-link>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import { helpers } from '../helper/portal_config';
//import 'swiper/swiper-bundle.css';
import 'swiper/swiper.scss'; 


export default {
  props: ['products'],
  data() {
    return {
      swiperOption: {
        helpers: '',
        loop: false,
        slidesPerView: 1.2,
        spaceBetween: 15,
        slidesPerGroup: 1,
        slidesOffsetAfter: 100,
        breakpoints: {
          667: {
            slidesPerView: 2.2,
            spaceBetween: 15,
          },
          767: {
            slidesPerView: 2.8,
            spaceBetween: 20,
          },
          1200: {
            slidesPerView: 3.8,
          },
          1600: {
            slidesPerView: 4.2,
          },
        },
      },
    };
  },
  computed: {
    isBookType() {
      return this.helpers.portal_name == 'Carefizz'; // change ebook portal name
    },
  },
  components: {
    Swiper,
    SwiperSlide
  },
  created() {
    this.helpers = helpers;
  },
};
</script>

<style scoped>
.card--v4.card--ebook .item-img img {
  object-fit: contain;
}

.card--v4.card--ebook .item-img {
  width: 50px;
  height: 90px;
}

@media (min-width: 1200px) {
  .card--v4.card--ebook .item-img {
    width: 90px;
    height: 120px;
  }
}

@media (min-width: 1600px) {
  .card--v4.card--ebook .item-img {
    width: 80px;
    height: 135px;
  }
}
</style>
