var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"page-content"},[_c('section',{staticClass:"section--products section--categories"},[_c('div',{staticClass:"section__inner",staticStyle:{"padding-right":"20px"}},[_c('div',{staticClass:"section__box section__box--nobg"},[_c('h2',{staticClass:"box-title box-title--category",class:[this.currentLanguage == 'AR' ? 'rtlText': '']},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"category-products"},_vm._l((_vm.products),function(item){return _c('div',{key:item.product_id,class:_vm.isBookType ? 'card--v4 card--ebook' : 'card--v4'},[_c('router-link',{attrs:{"event":_vm.isLoggedIn ? 'click' : '',"to":{
                  name: 'ProductDetails',
                  params: {
                    type: _vm.helpers.portal_name,
                    productId: item.product_id,
                  },
                }},nativeOn:{"click":function($event){return _vm.checkIsLogged({
                    name: 'ProductDetails',
                    params: {
                      type: _vm.helpers.portal_name,
                      productId: item.product_id,
                    },
                  })}}},[_c('div',{staticClass:"item-img"},[_c('img',{attrs:{"src":item.icon_small,"alt":_vm.title}})]),_c('div',{staticClass:"item-data"},[_c('div',{staticClass:"data-value"},[_c('h3',{staticClass:"data-title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"data-category"},[_vm._v(" "+_vm._s(item.short_description)+" ")])])])])],1)}),0)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }