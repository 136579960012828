<template>
  <div>
    <div class="page-banner">
      <h1>{{ pageTitle }}</h1>
    </div>

    <div class="page-content">
      <div class="page-content__inner">
        <div class="unsub" v-html="content" :class="[currentLanguage == 'AR' ? 'rtlText': '']">
        </div>
        <br/>
        <div id="unsubform">
              
          <form method="POST" @submit.prevent="submitPhoneNumber">
            <label for="phoneNumber" v-html="$t('unsubscribe.title')" :class="[currentLanguage == 'AR' ? 'rtlText': '']"></label>

            <input
              type="tel"
              id="phoneNumber"
              name="phone-number"
              v-model="phoneNumber"
              :placeholder="this.phoneFormat"
              :maxlength="this.phoneFormat.length"
              :style="[this.phoneIsValid ? { color: '#48A73D' } : '']"
            />
            <div>
              <span>
                (Format: {{ this.phoneFormat }})
              </span>
            </div>
            <div v-if="errors.length">
              <ul>
                <li v-for="error in errors" :key="error.id">
                  <span style="color: red" :class="[currentLanguage == 'AR' ? 'rtlText': '']">{{ error.message }}</span>
                </li>
              </ul>
            </div>
            <div v-if="success">
              <span style="color: green" :class="[currentLanguage == 'AR' ? 'rtlText': '']">{{ $t("unsubscribe.successMessage") }}</span>
            </div>
            <div class="div-recaptcha">
              <vue-recaptcha ref="recaptcha"  :loadRecaptchaScript="true"
                @verify="onVerify" sitekey="6LeaItAaAAAAAFEtCW_enXxRC9Dfv6vIOZHU0iyU">
              </vue-recaptcha>
            </div>
            
            <div class="form-button">
              <button type="submit" class="unsubscribe-btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.015"
                  height="15.325"
                  viewBox="0 0 20.015 15.325"
                >
                  <path
                    d="M16.262,79.011H12.978a.47.47,0,0,1-.469-.469V76.978a.47.47,0,0,1,.469-.469h3.284a1.25,1.25,0,0,0,1.251-1.251V67.753A1.25,1.25,0,0,0,16.262,66.5H12.978a.47.47,0,0,1-.469-.469V64.469A.47.47,0,0,1,12.978,64h3.284a3.754,3.754,0,0,1,3.753,3.753v7.506A3.754,3.754,0,0,1,16.262,79.011Zm-1.837-7.857L7.857,64.586a.94.94,0,0,0-1.6.665V69H.938A.936.936,0,0,0,0,69.942v3.753a.936.936,0,0,0,.938.938H6.255v3.753a.94.94,0,0,0,1.6.665l6.567-6.567A.946.946,0,0,0,14.425,71.154Z"
                    transform="translate(0 -64)"
                  />
                </svg>
                <span :class="[currentLanguage == 'AR' ? 'rtlText': '']">{{ $t("unsubscribe.button") }}</span>
              </button>
              
            </div>
          </form>
        </div>
        <br/>
        <div class="unsub"  :class="[currentLanguage == 'AR' ? 'rtlText': '']" v-if="$t('unsubscribe.afterForm') != 'unsubscribe.afterForm'" v-html="$t('unsubscribe.afterForm')">
        </div>
        
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import { helpers } from '../helper/portal_config';
import VueRecaptcha from 'vue-recaptcha';

const apiMethod = 'getPortalUnsubscription';

export default {
  name: 'Unsubscription',
  title: `${helpers.portal_name} Unsubscription`,
  data() {
    return {
      pageTitle: this.$i18n.t('unsubscription'),
      content: '',
      phoneNumber: '',
      phoneFormat: '',
      lastEnteredMobile: 'toto',
      robot: false,
      link: '',
      errors: [],
      success: false,
      helpers: helpers,
      currentLanguage: "",
      currentCountry: "",
    };
  },
  components: {
    VueRecaptcha
  },
  methods: {
    checkForm() {
      this.errors = [];
      this.success = false;
      if (this.phoneNumber) {
        // phone number was valid
        return true;
      }
      const errorObj = { id: 1, message: this.$i18n.t('unsubscribe.invalidPhone') };
      this.errors.push(errorObj);
      return false;
    },
    onVerify: function (response) {
      if (response) {
        this.robot = true;
        console.log('captcha OK');
      }
    },
    isCaptcha: function (response) {
      if (this.robot) {
        return true;
      }
      const errorObj = { id: 1, message: this.$i18n.t('unsubscribe.captchaError') };
      this.errors.push(errorObj);
      return false;
    },
    setCurrentCountry() {
      const { host } = window.location;
      this.currentCountry = host.split('.')[0].slice(0,2).toUpperCase();
    },
    setCurrentLanguage() {
      var lang = "";
      const { host } = window.location;
      var country = host.split('.')[0].slice(0,2).toUpperCase();
      if (helpers.countryDefaultLanguage.hasOwnProperty(country)) {
        lang = helpers.countryDefaultLanguage[country].toUpperCase();
      } else {
        lang = country.toUpperCase();
      }
      this.currentLanguage = lang;
    },
    getMessageForErrorCode(errorCode) {
      this.errors = [];
      const langErrorMsg = this.$i18n.t(`error.E${errorCode}`);
      if (langErrorMsg) {
        this.errorMsg = langErrorMsg;
      } else {
        // not found error message we can print out the default one
        this.errorMsg = this.$i18n.t('error.default');
      }
      const errorObj = {
        id: 222,
        message: this.errorMsg,
      };
      this.errors.push(errorObj);
    },
    setPhoneFormat() {
      this.phoneFormat = helpers.languagePhoneFormat[this.currentCountry];
    },
    submitPhoneNumber() {
      // need to check form before send it
      
      if (this.phoneNumber == this.lastEnteredMobile){
        return false;
      }
      if (!this.checkForm() || !this.isCaptcha()) return false;
      this.lastEnteredMobile = this.phoneNumber

      axios
        .get(
          `https://api.hubdelivery.io/store/UnsubscribePhoneNumber?service=${
            helpers.portal_name
          }&country=${this.currentCountry}&language=${this.currentLanguage}&phone_number=${
            this.phoneNumber
          }`,
        )
        .then((response) => {
          if (response.data.treatment == 1) {
            this.success = true;
          }
        })
        .catch((error) => {
          this.getMessageForErrorCode(error.response.data.error_code);
        });
    },
  },
  computed: {
    phoneIsValid() {
      if (this.currentCountry == 'EE' && this.phoneNumber && 
          (this.phoneNumber.length === this.phoneFormat.length || 
          this.phoneNumber.length === this.phoneFormat.length-1)) {
          return true;
      } else if (this.phoneNumber && this.phoneNumber.length === this.phoneFormat.length) {
        return true;
      }
      return false;
    },
  },
  created() {
    this.setCurrentLanguage();
    this.setCurrentCountry();
    this.setPhoneFormat();
    
    axios
      .get(
        `https://api.hubdelivery.io/store/${apiMethod}?portal_name=${
          helpers.portal_name
        }&country=${this.currentCountry}&language=${this.currentLanguage}`,
      )
      .then((response) => {
        if (response.data.treatment === 1) {
          const str = response.data.content;
          const stringData = str.replace(/\\n/g, '<br />');
          this.content = stringData;
        }
      });
  },
};
</script>
