var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section__inner section__inner--noRightpad section__inner--noLeftpad"},[_c('div',{staticClass:"section__box section__box--smToppad"},[_c('div',{staticClass:"section__head"},[_c('h2',{staticClass:"box-title"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"item-img"},[_c('router-link',{staticClass:"btn--outline btn--sm",class:[_vm.currentLanguage == 'AR' ? 'rtlTextWithoutAlign': ''],attrs:{"to":{
            name: 'ProductCategory',
            params: {
              type: _vm.currentType,
              title: _vm.title,
              id: _vm.categoryid,
            },
          }}},[_vm._v(_vm._s(_vm.$t("button.seemore"))+" ")])],1)]),_c('swiper',{staticClass:"swiper carousel-wrapper carousel-wrapper--nopad",attrs:{"options":_vm.swiperOption,"auto-update":true,"auto-destroy":false,"delete-instance-on-destroy":true,"cleanup-styles-on-destroy":true}},_vm._l((_vm.products),function(item){return _c('swiper-slide',{key:item.product_id,class:_vm.isBookType
            ? 'carousel-item card--v1 card--ebook'
            : 'carousel-item card--v1'},[_c('router-link',{attrs:{"to":{
            name: 'ProductDetails',
            params: {
              type: _vm.helpers.portal_name,
              productId: item.product_id,
            },
          }},nativeOn:{"click":function($event){return _vm.checkIsLogged({
              name: 'ProductDetails',
              params: {
                type: _vm.helpers.portal_name,
                productId: item.product_id,
              },
            })}}},[_c('div',[_c('div',{staticClass:"item-img"},[_c('img',{attrs:{"src":item.icon_small,"alt":item.title}})]),_c('div',{staticClass:"item-data"},[_c('h3',{staticClass:"data-title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"data-category"},[_vm._v(" "+_vm._s(item.short_description)+" ")])])])])],1)}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }