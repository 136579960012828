<template>
  <div>
    <div class="page-banner">
      <h1 v-html="pageTitle"></h1>
    </div>
    <div class="page-content">
      <div class="page-content__inner">
        <article class="article" v-html="$t('legals.impressum.content')">
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { helpers } from '../helper/portal_config';

export default {
  name: 'Impressum',
  title: `${helpers.portal_name} Impressum`,
  data() {
    return {
      pageTitle: this.$i18n.t('impressum'),
      currentLanguage: "",
      currentCountry: ""
    };
  },
  methods: {
    setCurrentCountry() {
      const { host } = window.location;
      this.currentCountry = host.split('.')[0].slice(0,2).toUpperCase();
    },
    setCurrentLanguage() {
      var lang = "";
      const { host } = window.location;
      var country = host.split('.')[0].slice(0,2).toUpperCase();
      if (helpers.countryDefaultLanguage.hasOwnProperty(country)) {
        lang = helpers.countryDefaultLanguage[country].toUpperCase();
      } else {
        lang = country.toUpperCase();
      }
      this.currentLanguage = lang;
    },
  },
  created() {
    this.setCurrentLanguage();
    this.setCurrentCountry();
  },
};
</script>
