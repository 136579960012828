<template>
  <div class="section__inner section__inner--noRightpad">
    <div class="section__box section__box--nobg">
      <div class="section__head">
        <h2 class="box-title">{{ title }}</h2>
        <div class="item-img">
          <router-link
            :to="{
              name: 'ProductCategory',
              params: {
                type: currentType,
                title: title,
                id: categoryid,
              },
            }"
            class="btn--outline btn--sm" :class="[currentLanguage == 'AR' ? 'rtlText': '']"
            >{{ $t("button.seemore") }}
          </router-link>
        </div>
      </div>

      <swiper
        class="swiper carousel-wrapper carousel-wrapper--nopad"
        :options="swiperOption"
        :auto-update="true"
        :auto-destroy="false"
        :delete-instance-on-destroy="true"
        :cleanup-styles-on-destroy="true"
      >
        <swiper-slide
          :class="
            isBookType
              ? 'carousel-item card--v2 card--ebook'
              : 'carousel-item card--v2'
          "
          v-for="item in products"
          :key="item.product_id"
        >
          <div
            class="item-img"
            @click="
              checkIsLogged({
                name: 'ProductDetails',
                params: {
                  type: helpers.portal_name,
                  productId: item.product_id,
                },
              })
            "
          >
            <router-link
              :to="{
                name: 'ProductDetails',
                params: {
                  type: helpers.portal_name,
                  productId: item.product_id,
                },
              }"
            >
              <img :src="item.icon_small" :alt="title" />
            </router-link>
          </div>
          <div class="item-data">
            <router-link
              :to="{
                name: 'ProductDetails',
                params: {
                  type: helpers.portal_name,
                  productId: item.product_id,
                },
              }"
              @click="
                checkIsLogged({
                  name: 'ProductDetails',
                  params: {
                    type: helpers.portal_name,
                    productId: item.product_id,
                  },
                })
              "
            >
              <div
                slot=""
                @click="
                  checkIsLogged({
                    name: 'ProductDetails',
                    params: {
                      type: helpers.portal_name,
                      productId: item.product_id,
                    },
                  })
                "
              >
                <h3 class="data-title">{{ item.title }}</h3>
                <div class="data-category">
                  {{ item.short_description }}
                </div>
              </div>
            </router-link>
            <router-link
              :to="{
                name: 'ProductDetails',
                params: {
                  type: helpers.portal_name,
                  productId: item.product_id,
                },
              }"
              class="item-btn"
              @click.native="
                checkIsLogged({
                  name: 'ProductDetails',
                  params: {
                    type: helpers.portal_name,
                    productId: item.product_id,
                  },
                })
              "
            >
              <div class="btn--next btn--primary btn--sm">
                <span v-if="item.type === 1" :class="[currentLanguage == 'AR' ? 'rtlTextWithoutAlign': '']">{{ $t("button.playGame") }}</span>
                <span v-else-if="item.type === 5" :class="[currentLanguage == 'AR' ? 'rtlTextWithoutAlign': '']">{{ $t("button.playVideo") }}</span>
                <span v-else :class="[currentLanguage == 'AR' ? 'rtlTextWithoutAlign': '']">{{ $t("button.download") }}</span>
              </div>
            </router-link>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { helpers } from "../helper/portal_config";
import EventBus from "../helper/event_bus";

//import "swiper/swiper-bundle.css";
import 'swiper/swiper.scss'; 

export default {
  name: "CarouselB",
  props: ["products", "title", "categoryid"],
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      helpers: "",
      currentType: null,
      currentLanguage: "",
      swiperOption: {
        loop: false,
        slidesPerView: 1.5,
        spaceBetween: 15,
        slidesOffsetAfter: 30,
        breakpoints: {
          480: {
            slidesPerView: 2.2,
          },
          667: {
            slidesPerView: 2.6,
            spaceBetween: 15,
          },
          767: {
            slidesPerView: 3.5,
            spaceBetween: 15,
          },
          1200: {
            slidesOffsetAfter: 0,
            slidesPerView: 5,
            grabCursor: false,
            touchRatio: 0,
            spaceBetween: 25,
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "globals/isLoggedIn",
    }),
    isBookType() {
      console.log(this.helpers.portal_name);
      return this.helpers.portal_name == "Carefizz"; // change ebook portal name
    },
  },
  methods: {
    checkIsLogged(link) {
      // if you are not login than showed modal
      if (!this.isLoggedIn) {
        EventBus.$emit("SHOW_SIGNIN_MODAL", link);
      }
    },
    getProductType() {
      let menu = this.helpers.menu;
      menu.forEach((item) => {
        if (item.isActive == true) {
          this.currentType = item.type;
        }
      });
    },
    setCurrentLanguage() {
      var lang = "";
      const { host } = window.location;
      var country = host.split('.')[0].slice(0,2).toUpperCase();
      if (helpers.countryDefaultLanguage.hasOwnProperty(country)) {
        lang = helpers.countryDefaultLanguage[country].toUpperCase();
      } else {
        lang = country.toUpperCase();
      }
      this.currentLanguage = lang;
    },
  },
  created() {
    this.helpers = helpers;
    this.getProductType();
    this.setCurrentLanguage();
  },
};
</script>

<style scoped>
.card--v2.card--ebook .item-img {
  height: 250px;
}

@media (min-width: 1440px) {
  .card--v2.card--ebook .item-img {
    height: 300px;
  }
}
@media (min-width: 1600px) {
  .card--v2.card--ebook .item-img {
    height: 380px;
  }
}

@media (min-width: 1921px) {
  .card--v2.card--ebook .item-img {
    height: 430px;
  }
}
</style>
