/* Portal configurations - global scope */

/* Define a product types for the portal.
* id: product type's id
* name: product type's name
* available:
*/
const helper = {};

const accent_color = 'version3'; // color schema of the portal

const portal_name = 'Voxistream';

const activate_website = 1; // 1(all), 2(only portal), 3(only landing)

const landing_name = "Voxistream"; // FirstPortal, EbookPortal

const product_type = 4;

const portal_url = "voxistream.com";

const country_landing_url = [{
    'UK': 'https://premium.voxistream.com/uQK114/',
    'CZ': 'https://premium.voxistream.com/cZAjjQ/'
}];

const country_default_language = {
    'UK': 'EN'
};

const product_menu_items = [{
        id: 1,
        name: 'Home',
        available: 1,
        type: 4,
        banner: 0,
        isActive: true,
        countryExclusion: ''
    },
    {
        id: 2,
        name: 'Online Games',
        available: 1,
        type: 1,
        banner: 0,
        isActive: false,
        countryExclusion: 'CZ'
    },
    {
        id: 3,
        name: 'Android Games Apps',
        available: 1,
        type: 2,
        banner: 0,
        isActive: false,
        countryExclusion: 'CZ'
    },
	{
        id: 6,
        name: 'Apps',
        available: 1,
        type: 6,
        banner: 0,
        isActive: false,
        countryExclusion: 'CZ'
    },
	{
        id: 5,
        name: 'Funny Videos',
        available: 1,
        type: 5,
        banner: 0,
        isActive: false,
        countryExclusion: 'SK'
    },
    {
        id: 7,
        name: 'Fitness',
        available: 1,
        type: 7,
        banner: 0,
        isActive: false,
        countryExclusion: 'SK'
    }
];

const language_phones_format = {
    UK: '+44xxxxxxxxx',
    CZ: '7xxxxxxxx'
};

/*
 * Rating and Favourite feature enabled (1, 0)
 */
const use_rating = 1;
const use_favourite = 1;

// apis
helper.api_PhoneNumber = 'https://api.hubdelivery.io/landing/providePhoneNumberV2';
helper.api_ValidatePin = 'https://api.hubdelivery.io/landing/validatePinCodeV2';
helper.api_SendAccessCodeByEmail = 'https://api.hubdelivery.io/landing/sendAccessCodeByEmail';

helper.api_Search = 'https://api.hubdelivery.io/store/searchProducts';

// tracking api url
helper.trackingAPI = 'https://keepan.eyeonev.com/ent';
helper.ratingsAPI = "https://api.hubdelivery.io/store/addProductRating";
helper.favouriteAPI = "https://api.hubdelivery.io/store/addToFavourite";
helper.favouriteRemoveAPI = "https://api.hubdelivery.io/store/removeFromFavourite";

// tracking default and generated values
helper.affiliate_landing = 'AFFED8bcEzc'; // aff
helper.source_landing = '997PROVID998'; // src
helper.landing_page_id = 'LPC9crQndF8'; // lpc

// apis
helper.landingUrl = country_landing_url;
helper.languagePhoneFormat = language_phones_format;
helper.countryDefaultLanguage = country_default_language;
helper.menu = product_menu_items;
helper.portal_name = portal_name;
helper.portal_type = product_type;
helper.landing_name = landing_name;
helper.portal_url = portal_url;
helper.accent_color = accent_color;
helper.actice_website = activate_website;
helper.hasStars = use_rating;
helper.hasFavourite = use_favourite;

export const helpers = helper;