var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header',{staticClass:"page-header"},[_c('div',{staticClass:"page-header__left"},[_c('button',{staticClass:"menu-btn",on:{"click":_vm.toggleSideMenu}},[_c('span',{staticClass:"sr-only"},[_vm._v("Menu")])]),_c('a',{staticClass:"page-header__logo",attrs:{"href":"/"}},[(_vm.currentCountry == 'RO' && _vm.helpers.portal_name == 'Fitlandclub')?_c('img',{attrs:{"src":require("../assets/images/portal-logo-ro.png"),"alt":"logo"}}):_vm._e(),(_vm.currentCountry != 'RO' || _vm.helpers.portal_name != 'Fitlandclub')?_c('img',{attrs:{"src":require("../assets/images/portal-logo.png"),"alt":"logo"}}):_vm._e()]),_c('nav',{staticClass:"page-header__menu"},[_c('ul',_vm._l((_vm.menu),function(item){return _c('li',{key:item.id,class:[_vm.currentLanguage == 'AR' ? 'rtlTextWithoutAlign': '', item.isActive === true ? 'is-active':''],on:{"click":function($event){return _vm.setActive(item)}}},[(item.available != 0 && !item.countryExclusion.includes(_vm.currentCountry))?_c('router-link',{attrs:{"to":{
                  name: 'ProductType',
                  params: {
                      name: _vm.helpers.portal_name,
                      id: item.type
                  },
              }}},[_c('div',{staticClass:"menu-link"},[_vm._v(" "+_vm._s(_vm.$t('menu.id'+item.id))+" ")])]):_vm._e()],1)}),0)])]),_c('div',{staticClass:"page-header__actions"},[_c('Search'),(_vm.isLoggedIn)?_c('button',{staticClass:"logout-btn",attrs:{"title":"logout"},on:{"click":_vm.logout}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16.969","height":"17.242","viewBox":"0 0 16.969 17.242"}},[_c('path',{attrs:{"d":"M21.41,1.851a8.483,8.483,0,1,1-9.86,0,.824.824,0,0,1,1.2.263l.541.961a.821.821,0,0,1-.226,1.061,5.747,5.747,0,1,0,6.839,0,.816.816,0,0,1-.222-1.057l.541-.961a.82.82,0,0,1,1.191-.267ZM17.853,9.032V.821A.819.819,0,0,0,17.031,0H15.937a.819.819,0,0,0-.821.821V9.032a.819.819,0,0,0,.821.821h1.095A.819.819,0,0,0,17.853,9.032Z","transform":"translate(-8)"}})])]):_vm._e(),(!_vm.isLoggedIn)?_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.singInModal",modifiers:{"singInModal":true}}],staticClass:"signin-btn"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"20.015","height":"15.325","viewBox":"0 0 20.015 15.325"}},[_c('path',{attrs:{"d":"M16.262,79.011H12.978a.47.47,0,0,1-.469-.469V76.978a.47.47,0,0,1,.469-.469h3.284a1.25,1.25,0,0,0,1.251-1.251V67.753A1.25,1.25,0,0,0,16.262,66.5H12.978a.47.47,0,0,1-.469-.469V64.469A.47.47,0,0,1,12.978,64h3.284a3.754,3.754,0,0,1,3.753,3.753v7.506A3.754,3.754,0,0,1,16.262,79.011Zm-1.837-7.857L7.857,64.586a.94.94,0,0,0-1.6.665V69H.938A.936.936,0,0,0,0,69.942v3.753a.936.936,0,0,0,.938.938H6.255v3.753a.94.94,0,0,0,1.6.665l6.567-6.567A.946.946,0,0,0,14.425,71.154Z","transform":"translate(0 -64)","fill":"#0a0a0a"}})]),_c('span',{class:[_vm.currentLanguage == 'AR' ? 'rtlTextWithoutAlign': '']},[_vm._v(_vm._s(_vm.$t('signin')))])]):_vm._e()],1)]),_c('SideMenu',{attrs:{"open":_vm.isOpen},on:{"closed":_vm.closeSideMenu}}),_c('SignInModal')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }