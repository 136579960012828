<template>
  <div
    class="section__inner section__inner--noRightpad section__inner--noLeftpad"
  >
    <div class="section__box section__box--smToppad">
      <div class="section__head">
        <!-- <h2 class="box-title">{{ title }}</h2> -->
        <h2 class="box-title">{{ title }}</h2>
        <div class="item-img">
          <router-link
            :to="{
              name: 'ProductCategory',
              params: {
                type: currentType,
                title: title,
                id: categoryid,
              },
            }"
            class="btn--outline btn--sm" :class="[currentLanguage == 'AR' ? 'rtlTextWithoutAlign': '']"
            >{{ $t("button.seemore") }}
          </router-link>
        </div>
      </div>
      <swiper
        class="swiper carousel-wrapper carousel-wrapper--nopad"
        :options="swiperOption"
        :auto-update="true"
        :auto-destroy="false"
        :delete-instance-on-destroy="true"
        :cleanup-styles-on-destroy="true"
      >
        <swiper-slide
          :class="
            isBookType
              ? 'carousel-item card--v1 card--ebook'
              : 'carousel-item card--v1'
          "
          v-for="item in products"
          :key="item.product_id"
        >
          <router-link
            :to="{
              name: 'ProductDetails',
              params: {
                type: helpers.portal_name,
                productId: item.product_id,
              },
            }"
            @click.native="
              checkIsLogged({
                name: 'ProductDetails',
                params: {
                  type: helpers.portal_name,
                  productId: item.product_id,
                },
              })
            "
          >
            <div>
              <div class="item-img">
                <img :src="item.icon_small" :alt="item.title" />
              </div>
              <div class="item-data">
                <h3 class="data-title">{{ item.title }}</h3>
                <div class="data-category">
                  {{ item.short_description }}
                </div>
              </div>
            </div>
          </router-link>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { helpers } from "../helper/portal_config";
import EventBus from "../helper/event_bus";

//import "swiper/swiper-bundle.css";
import 'swiper/swiper.scss'; 

export default {
  name: "CarouselA",
  props: ["products", "title", "categoryid"],
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      link: "",
      helpers: "",
      currentType: null,
      currentLanguage: "",
      swiperOption: {
        loop: false,
        slidesPerView: 1.8,
        spaceBetween: 15,
        slidesOffsetAfter: 20,
        breakpoints: {
          400: {
            slidesPerView: 2.1,
          },
          667: {
            slidesPerView: 3.2,
            spaceBetween: 15,
          },
          767: {
            slidesPerView: 3.5,
            spaceBetween: 20,
          },
          1200: {
            slidesOffsetAfter: 0,
            grabCursor: false,
            touchRatio: 0,
            slidesPerView: 7,
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "globals/isLoggedIn",
    }),
    isBookType() {
      return this.helpers.portal_name == "Carefizz"; // change ebook portal name
    },
  },
  methods: {
    checkIsLogged(link) {
      // if you are not login than showed modal
      if (!this.isLoggedIn) {
        EventBus.$emit("SHOW_SIGNIN_MODAL", link);
      }
    },
    getProductType() {
      let menu = this.helpers.menu;
      menu.forEach((item) => {
        if (item.isActive == true) {
          this.currentType = item.type;
        }
      });
    },
    setCurrentLanguage() {
      var lang = "";
      const { host } = window.location;
      var country = host.split('.')[0].slice(0,2).toUpperCase();
      if (helpers.countryDefaultLanguage.hasOwnProperty(country)) {
        lang = helpers.countryDefaultLanguage[country].toUpperCase();
      } else {
        lang = country.toUpperCase();
      }
      this.currentLanguage = lang;
    },
  },
  created() {
    this.helpers = helpers;
    this.productSizes = 7;
    this.getProductType();
    this.setCurrentLanguage();
  },
};
</script>

<style scoped>
.card--v1 .item-img {
  height: 42vw;
}

.card--v1 .item-img img {
  object-fit: contain;
  object-position: left;
  border-radius: 0;
}

.card--v1.card--ebook .item-img {
  height: 150px;
}

.card--v1.card--ebook .item-img img {
  object-fit: contain;
  object-position: left;
  border-radius: 0;
}

@media (min-width: 670px) {
  .card--v1 .item-img {
    height: 200px;
  }
}

@media (min-width: 1280px) {
  .card--v1.card--ebook .item-img {
    height: 150px;
  }

  .card--v1 .item-img {
    height: 12vw;
  }
}

@media (min-width: 1440px) {
  .card--v1.card--ebook .item-img {
    height: 200px;
  }
}
@media (min-width: 1600px) {
  .card--v1.card--ebook .item-img {
    height: 230px;
  }
}
@media (min-width: 1921px) {
  .card--v1.card--ebook .item-img {
    height: 260px;
  }
}
</style>
