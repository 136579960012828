<template :class="[this.currentLanguage == 'AR' ? 'rtlText': '']">
  <div :class="open ? 'slide-menu is-showed' : 'slide-menu'">
    <h4 class="slide-menu__title" :color="helpers.accent_color">Menu</h4>
    <ul class="slide-menu__list">
      <li v-for="item in menu" :key="item.id" :class="{'is-active': item.isActive === true }" @click="setActive(item)">
        <router-link
          v-if="item.available != 0 && !item.countryExclusion.includes(currentCountry)"
          :to="{
            name: 'ProductType',
            params: { id: item.type, name: helpers.portal_name },
          }"
          >{{ $t('menu.id'+item.id) }}</router-link
        >
      </li>
    </ul>

    <ul class="slide-menu__list slide-menu__list--utility">
      <li>
        <router-link to="/contact" v-on:click.native="closeSideMenu">{{
          $t("contactus")
        }}</router-link>
      </li>
      <li>
        <router-link to="/faq" v-on:click.native="closeSideMenu">{{
          $t("faq")
        }}</router-link>
      </li>
      <li>
        <router-link to="/terms" v-on:click.native="closeSideMenu">{{
          $t("terms")
        }}</router-link>
      </li>
      <li>
        <router-link to="/privacy" v-on:click.native="closeSideMenu">{{
          $t("privacy")
        }}</router-link>
      </li>
      <li>
        <router-link to="/cookies-policy" v-on:click.native="closeSideMenu">{{
          $t("cookiespolicy")
        }}</router-link>
      </li>
      <li>
        <router-link to="/unsubscription" v-on:click.native="closeSideMenu">{{
          $t("unsubscription")
        }}</router-link>
      </li>
    </ul>

    <button class="slide-menu__close js-close-menu" @click="closeSideMenu">
      <span class="sr-only">Close menu</span>
      <svg
        class="close-icon"
        xmlns="http://www.w3.org/2000/svg"
        width="16.635"
        height="16.635"
        viewBox="0 0 16.635 16.635"
      >
        <path
          d="M11.471,88.317,16.2,83.588a1.487,1.487,0,0,0,0-2.1l-1.051-1.051a1.487,1.487,0,0,0-2.1,0L8.317,85.164,3.588,80.435a1.487,1.487,0,0,0-2.1,0L.435,81.486a1.487,1.487,0,0,0,0,2.1l4.729,4.729L.435,93.047a1.487,1.487,0,0,0,0,2.1L1.486,96.2a1.487,1.487,0,0,0,2.1,0l4.729-4.729L13.047,96.2a1.487,1.487,0,0,0,2.1,0L16.2,95.149a1.487,1.487,0,0,0,0-2.1Z"
          transform="translate(0 -80)"
          fill="#ececec"
        />
      </svg>
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { helpers } from '../helper/portal_config';
import EventBus from '../helper/event_bus';

export default {
  name: 'SideMenu',
  data() {
    return {
      menu: [],
      helpers: '',
      isActive: true,
      currentCountry: "",
    };
  },
  props: ['open'],
  methods: {
    closeSideMenu() {
      this.$emit('closed');
    },
    setActive(item) {
      // start a bus to menu
      EventBus.$emit('SET_MENU_ACTIVE', item);
    },
    setCurrentCountry() {
      const { host } = window.location;
      this.currentCountry = host.split('.')[0].slice(0,2).toUpperCase();
    },
  },
  watch: {
    $route(to, from) {
      this.$emit('closed');
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'globals/isLoggedIn',
    }),
  },
  created() {
    this.setCurrentCountry();
    this.helpers = helpers;
    this.menu = helpers.menu;
  },
};
</script>
