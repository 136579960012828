<template>
  <div class="section__inner section__inner--noRightpad">
    <div class="section__box section__box--nobg">
      <div class="section__head">
        <h2 class="box-title">{{ title }}</h2>
        <div class="item-img">
          <router-link
            :to="{
              name: 'ProductCategory',
              params: {
                type: currentType,
                title: title,
                id: categoryid,
              },
            }"
            class="btn--outline btn--sm" :class="[currentLanguage == 'AR' ? 'rtlText': '']"
            >{{ $t("button.seemore") }}
          </router-link>
        </div>
      </div>
      <swiper
        class="swiper carousel-wrapper carousel-wrapper--smpad"
        :options="swiperOption"
        :auto-update="true"
        :auto-destroy="false"
        :delete-instance-on-destroy="true"
        :cleanup-styles-on-destroy="true"
      >
        <swiper-slide
          :class="
            isBookType
              ? 'carousel-item card--v3 card--ebook'
              : 'carousel-item card--v3'
          "
          v-for="item in products"
          :key="item.product_id"
        >
          <div
            class="item-img"
            @click="
              checkIsLogged({
                name: 'ProductDetails',
                params: {
                  type: helpers.portal_name,
                  productId: item.product_id,
                },
              })
            "
          >
            <router-link
              :to="{
                name: 'ProductDetails',
                params: {
                  type: helpers.portal_name,
                  productId: item.product_id,
                },
              }"
              @click="
                checkIsLogged({
                  name: 'ProductDetails',
                  params: {
                    type: helpers.portal_name,
                    productId: item.product_id,
                  },
                })
              "
            >
              <img
                :src="item.icon_small"
                :alt="title"
                @error="imageLoadError(item)"
              />
            </router-link>
          </div>
          <div class="item-data">
            <router-link
              :to="{
                name: 'ProductDetails',
                params: {
                  type: helpers.portal_name,
                  productId: item.product_id,
                },
              }"
            >
              <div class="item-data-left">
                <h3
                  @click="
                    checkIsLogged({
                      name: 'ProductDetails',
                      params: {
                        type: helpers.portal_name,
                        productId: item.product_id,
                      },
                    })
                  "
                  class="data-title"
                >
                  {{ item.title }}
                </h3>
                <div class="data-category">
                  {{ item.short_description }}
                </div>
              </div>
            </router-link>
            <router-link
              class="item-btn"
              :to="{
                name: 'ProductDetails',
                params: {
                  type: helpers.portal_name,
                  productId: item.product_id,
                },
              }"
              @click.native="
                checkIsLogged({
                  name: 'ProductDetails',
                  params: {
                    type: helpers.portal_name,
                    productId: item.product_id,
                  },
                })
              "
            >
              <div class="btn--primary btn--sm">
                <span v-if="item.type === 1" :class="[currentLanguage == 'AR' ? 'rtlTextWithoutAlign': '']">{{ $t("button.playGame") }}</span>
                <span v-else-if="item.type === 5" :class="[currentLanguage == 'AR' ? 'rtlTextWithoutAlign': '']">{{ $t("button.playVideo") }}</span>
                <span v-else :class="[currentLanguage == 'AR' ? 'rtlTextWithoutAlign': '']">{{ $t("button.download") }}</span>
              </div>
            </router-link>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { helpers } from "../helper/portal_config";
import EventBus from "../helper/event_bus";

//import "swiper/swiper-bundle.css";
import 'swiper/swiper.scss'; 

export default {
  name: "CarouselC",
  props: ["products", "title", "categoryid"],
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      helpers: "",
      currentType: null,
      currentLanguage: "",
      swiperOption: {
        loop: false,
        slidesPerView: 1.1,
        slidesPerColumn: 3,
        slidesOffsetAfter: 100,
        spaceBetween: 20,
        slidesPerGroup: 1,
        breakpoints: {
          667: {
            slidesPerView: 1.8,
            slidesPerColumn: 3,
          },
          767: {
            slidesPerView: 2.2,
            slidesPerColumn: 3,
          },
          1200: {
            slidesOffsetAfter: 0,
            grabCursor: false,
            spaceBetween: 20,
            touchRatio: 0,
            slidesPerView: 3,
            slidesPerColumn: 2,
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "globals/isLoggedIn",
    }),
    isBookType() {
      console.log(this.helpers.portal_name);
      return this.helpers.portal_name == "Carefizz"; // change ebook portal name
    },
  },
  methods: {
    checkIsLogged(link) {
      // if you are not login than showed modal
      if (!this.isLoggedIn) {
        EventBus.$emit("SHOW_SIGNIN_MODAL", link);
      }
    },
    imageLoadError(item) {
      item.icon_small = "https://via.placeholder.com/150?text=No+Image";
    },
    getProductType() {
      let menu = this.helpers.menu;
      menu.forEach((item) => {
        if (item.isActive == true) {
          this.currentType = item.type;
        }
      });
    },
    setCurrentLanguage() {
      var lang = "";
      const { host } = window.location;
      var country = host.split('.')[0].slice(0,2).toUpperCase();
      if (helpers.countryDefaultLanguage.hasOwnProperty(country)) {
        lang = helpers.countryDefaultLanguage[country].toUpperCase();
      } else {
        lang = country.toUpperCase();
      }
      this.currentLanguage = lang;
    },
  },
  created() {
    this.helpers = helpers;
    this.getProductType();
    this.setCurrentLanguage();
  },
};
</script>

<style lang="scss" scoped>
.card--v3 {
  margin-top: 20px;
  margin-bottom: 0;
}

.swiper {
  height: 360px;
  margin-left: auto;
  width: 100%;
  margin-right: auto;

  .swiper-slide {
    height: 90px;
    //margin-bottom: 10px;
    //margin-right: 20px;
  }

  @media (min-width: 767px) {
    height: 380px;

    .swiper-slide {
      height: 90px;
    }
  }

  @media (min-width: 1200px) {
    height: 350px;

    .swiper-slide {
      height: 120px;
      margin-bottom: 20px;
      margin-right: 20px;
    }
  }

  @media (min-width: 1600px) {
    height: 350px;

    .swiper-slide {
      height: 120px;
      margin-bottom: 20px;
      margin-right: 20px;
    }
  }
}

.card--v3.card--ebook .item-img img {
  object-fit: contain;
}

.card--v3.card--ebook .item-img {
  width: 50px;
  height: 70px;
  min-width: 60px;
  flex: 1 0 50px;
}

@media (min-width: 1200px) {
  .card--v3.card--ebook .item-img {
    width: 70px;
    height: 100px;
    flex: 1 0 70px;
  }
}

@media (min-width: 1600px) {
  .card--v3.card--ebook .item-img {
    width: 80px;
    height: 100px;
    flex: 1 0 80px;
  }
}
</style>
