<template>
  <div :class="[this.currentLanguage == 'AR' ? 'rtlText': '']">
    <div class="page-banner">
      <h1 v-html="pageTitle"></h1>
    </div>
    <div class="page-content">
      <div class="page-content__inner">
        <div class="faqs">
          <Accordition v-for="faq in faqs" :faq="faq" :key="faq.order" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { helpers } from '../helper/portal_config';
import Accordition from '../components/Accordition';

const apiMethod = 'getPortalFaq';

export default {
  name: 'Faq',
  title: `${helpers.portal_name} FAQ`,
  data() {
    return {
      pageTitle: this.$i18n.t('faq'),
      faqs: [],
      active: false,
      currentLanguage: "",
      currentCountry: ""
    };
  },
  components: {
    Accordition,
  },
  methods: {
    toggleAccordion(event) {
      this.active = !this.active;
    },
    setCurrentCountry() {
      const { host } = window.location;
      this.currentCountry = host.split('.')[0].slice(0,2).toUpperCase();
    },
    setCurrentLanguage() {
      var lang = "";
      const { host } = window.location;
      var country = host.split('.')[0].slice(0,2).toUpperCase();
      if (helpers.countryDefaultLanguage.hasOwnProperty(country)) {
        lang = helpers.countryDefaultLanguage[country].toUpperCase();
      } else {
        lang = country.toUpperCase();
      }
      this.currentLanguage = lang;
    },
  },
  created() {
    this.setCurrentLanguage();
    this.setCurrentCountry();

    axios
      .get(
        `https://api.hubdelivery.io/store/${apiMethod}?portal_name=${
          helpers.portal_name
        }&country=${this.currentCountry}&language=${this.currentLanguage}`,
      )
      .then((response) => {
        if (response.data.treatment === 1) {
          this.faqs = response.data.faq;
        }
      });
  },
};
</script>
