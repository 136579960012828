import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import globals from './modules/global';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    globals,
  },
});
