<template>
  <div>
    <div class="page-content">
      <!--ebook type, 3 -->
      <div
        v-if="product.type != 5"
        :class="
          isBookType ? 'product-entry product-entry--ebook' : 'product-entry'
        "
        v-bind:style="{
          'background-image': 'url(' + product.banner_small + ')',
        }"
      >
        <div class="product-entry__content">
          <div class="product-entry__head">
            <div class="head-image">
              <img :src="product.icon_small" :alt="product.title" />
            </div>
          </div>
          <div class="product-entry__body">
            <div class="body-head-row">
              <h1 class="head-title">{{ product.title }}</h1>
              <p class="head-category">{{ getProductType(product.type) }}</p>
              <Stars :product="product" v-if="helpers.hasStars == 1 && productLoaded" />
            </div>

            <div class="body-row body-row--category">
              <h4 class="body-row__title" :class="[this.currentLanguage == 'AR' ? 'rtlText': '']">{{ $t("product.categories") }}</h4>
              <div class="body-row__text" :class="[this.currentLanguage == 'AR' ? 'rtlText': '']">{{ product.categories }}</div>
            </div>
            <!-- <div class="body-row body-row--description">
                            <h4 class="body-row__title">DESCRIPTION</h4>
                            <div class="body-row__text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam mi
                                justo, varius in elementum et, cursus nec lectus.</div>
              </div>-->
            <div class="body-row body-row--compatiblity">
              <h4 class="body-row__title" :class="[this.currentLanguage == 'AR' ? 'rtlText': '']">{{ $t("product.compatibility") }}</h4>
              <div class="body-row__platforms">
                <ul>
                  <li
                    v-for="c in compatibility"
                    style="display: inline-block; margin-right: 0"
                  >
                    <img
                      class="android"
                      v-if="c == 'android' || c == 'mobile'"
                      :title="c"
                      src="../assets/images/android.svg"
                      alt="android"
                    />
                    <img
                      class="ios"
                      v-else-if="c == 'apple' || c == 'mobile'"
                      :title="c"
                      src="../assets/images/apple.svg"
                      alt="ios"
                    />
                    <img
                      class="windows"
                      v-else-if="c == 'PC'"
                      :title="c"
                      src="../assets/images/windows.svg"
                      alt="windows"
                    />
                  </li>
                </ul>
              </div>
            </div>

            <div class="button-wrapper" :class="[this.currentLanguage == 'AR' ? 'rtlText': '']">
              <a
                @click.prevent="goToLinkwithApi(product.product_id)"
                href="#"
                class="product-entry__action-btn"
                >
                <span v-if="product.type === 1">{{ $t("button.playGame") }}</span>
                <span v-else-if="product.type === 5">{{ $t("button.playVideo") }}</span>
                <span v-else>{{ $t("button.download") }}</span>
              </a>
             <Favourite :product="product" v-if="helpers.hasFavourite == 1 && productLoaded" />
            </div>
          </div>
        </div>
      </div>

      <!-- Video type details -->
      <product-details-video
        v-if="product.type == 5"
        :product="product"
      ></product-details-video>

      <section>
        <div class="section__inner">
          <article
            :class="
              product.type != 5
                ? 'product-entry__article product-entry__article--down'
                : 'product-entry__article'
            "
            v-show="
              product.instructions ||
              product.description_long ||
              product.description_short
            "
          >
            <h2
              :class="
                [helpers.accent_color == 'red'
                  ? 'article-title article-title--red'
                  : 'article-title',
                 this.currentLanguage == 'AR' ? 'rtlText': ''
                ]
              "
            >
              {{ $t("product.description") }}
            </h2>
            <div v-html="product.description_short"></div>

            <h2 :class="[this.currentLanguage == 'AR' ? 'rtlText': '']">{{ $t("product.details") }}</h2>
            <div v-html="product.description_long"></div>

            <div v-if="product.instructions">
              <h2 :class="[this.currentLanguage == 'AR' ? 'rtlText': '']">{{ $t("product.instructions") }}</h2>
              <div v-html="product.instructions"></div>
            </div>
          </article>
        </div>
      </section>

      <!-- start: related products -->
      <section
        class="section--related-products"
        v-if="relatedProducts.length > 0"
      >
        <RelatedItem :products="relatedProducts" />
      </section>
      <!-- end: related products -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { helpers } from "../helper/portal_config";
import RelatedItem from "../components/RelatedItem";
import ProductDetailsVideo from "./ProductDetailsVideo";
import Stars from "@/components/Stars";
import Favourite from "@/components/Favourite";

const api = "getProductDetails";
const related_api = "getProductDetails";
const retrieveProduct_api = "retrieveProduct";

export default {
  name: "ProductDetails",
  title: helpers.portal_name,
  props: ["id"],
  components: {
    RelatedItem,
    ProductDetailsVideo,
    Stars,
    Favourite,
  },
  data() {
    return {
      helpers: "",
      pageTitle: "Product Details",
      product: {},
      relatedProducts: [],
      compatibility: [],
      productLoaded: false,
      currentLanguage: "",
      currentCountry: ""
    };
  },
  methods: {
    getRelatedProducts($relatedProducts) {
      this.relatedProducts = [];
      if ($relatedProducts == null) return false;

      $relatedProducts.forEach((element) => {
        setTimeout(() => {
          this.relatedProducts.push(element);
        }, 200);
      });
    },
    getProductType(type) {
      if (type === 1) {
        return this.$i18n.t("type.item1");
      }
      if (type === 2) {
        return this.$i18n.t("type.item2");
      }
      if (type === 3) {
        return this.$i18n.t("type.item3");
      }
      if (type === 4) {
        return this.$i18n.t("type.item4");
      }
      if (type === 5) {
        return this.$i18n.t("type.item5");
      }
      if (type === 6) {
        return this.$i18n.t("type.item6");
      }
    },
    goToLinkwithApi(productID) {
      window.open(
        `https://api.hubdelivery.io/store/${retrieveProduct_api}?portal_name=${
          this.$route.params.type || helpers.portal_name
        }&country=${this.currentCountry}&language=${this.currentLanguage}&access_code=${
          this.accessCode
        }&product_id=${productID}`
      );
    },
    setCurrentCountry() {
      const { host } = window.location;
      this.currentCountry = host.split('.')[0].slice(0,2).toUpperCase();
    },
    setCurrentLanguage() {
      var lang = "";
      const { host } = window.location;
      var country = host.split('.')[0].slice(0,2).toUpperCase();
      if (helpers.countryDefaultLanguage.hasOwnProperty(country)) {
        lang = helpers.countryDefaultLanguage[country].toUpperCase();
      } else {
        lang = country.toUpperCase();
      }
      this.currentLanguage = lang;
    },
  },
  computed: {
    ...mapGetters({
      accessCode: "globals/accessCode",
    }),
    isBookType() {
      console.log(this.helpers.portal_name);
      return this.helpers.portal_name == "Carefizz"; // change ebook portal name
    },
  },
  filters: {
    n2br(value) {
      if (value === null) {
        return "";
      }
      return value.replace(/\\n/g, "<br />");
    },
  },
  watch: {
    $route(to, from) {
      this.product = [];
      this.compatibility = [];
      const routeID = this.$route.params.productId;
      axios
        .get(
          `https://api.hubdelivery.io/store/${api}?portal_name=${
            this.$route.params.type || helpers.portal_name
          }&country=${this.currentCountry}&language=${this.currentLanguage}&access_code=${
            this.accessCode
          }&product_id=${routeID}`
        )
        .then((response) => {
          if (response.data.treatment == 1) {
            response.data.product.description_short = this.$options.filters.n2br(
              response.data.product.description_short
            );
            response.data.product.description_long = this.$options.filters.n2br(
              response.data.product.description_long
            );
            response.data.product.instructions = this.$options.filters.n2br(
              response.data.product.instructions
            );

            this.product = response.data.product;
            if (this.product.compatibility.length > 0) {
              this.compatibility = this.product.compatibility
                .split(",")
                .map((item) => item.trim());
              // get relatedProduct
            }
            this.getRelatedProducts(this.product.related_products_array);
          }
        });
    },
  },
  created() {
    const routeID = this.$route.params.productId;
    this.helpers = helpers;
    //console.log('enzo in ProductDetails page');

    this.setCurrentLanguage();
    this.setCurrentCountry();
    axios
      .get(
        `https://api.hubdelivery.io/store/${api}?portal_name=${
          this.$route.params.type || helpers.portal_name
        }&country=${this.currentCountry}&language=${this.currentLanguage}&access_code=${
          this.accessCode
        }&product_id=${routeID}`
      )
      .then((response) => {
        if (response.data.treatment == 1) {
          response.data.product.description_short = this.$options.filters.n2br(
            response.data.product.description_short
          );
          response.data.product.description_long = this.$options.filters.n2br(
            response.data.product.description_long
          );
          response.data.product.instructions = this.$options.filters.n2br(
            response.data.product.instructions
          );

          this.product = response.data.product;

          this.productLoaded = true;

          if (this.product.compatibility.length > 0) {
            this.compatibility = this.product.compatibility
              .split(",")
              .map((item) => item.trim());
            // get relatedProduct
          }
          this.getRelatedProducts(this.product.related_products_array);
        }
      });
  },
};
</script>

<style>
.product-entry.product-entry--ebook .product-entry__head img {
  object-fit: contain;
  box-shadow: none;
  height: 240px;
  border-radius: 0;
}

.article-title:before {
  background: #ffdd3d;
}

.article-title--red:before {
  background: #f6212b;
}

@media (min-width: 1200px) {
  .product-entry.product-entry--ebook .product-entry__head img {
    object-position: right top;
    height: 400px;
  }
}

.product-entry__body .c-stars {
  margin-bottom: 15px;
}

.product-entry__body .c-stars .vue-star-rating {
  justify-content: center;
}

@media (min-width: 1200px) {
  .product-entry__body .c-stars .vue-star-rating {
    justify-content: flex-start;
  }

  article.product-entry__article--down {
    margin-top: -45px;
  }
}

.button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 30px;
}

@media (min-width: 1200px) {
  .button-wrapper {
    justify-content: flex-start;
  }
}

.button-wrapper .product-entry__action-btn {
  margin-top: 0;
}

.button-wrapper .c-favourite {
  margin-left: 15px;
  margin-top: 0;
}

.button-wrapper .c-favourite__btn {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #fff;
}

@media (max-width: 667px) {
  .button-wrapper .c-favourite {
    margin-left: 0;
  }
}
</style>
