<template>
  <div :class="[this.currentLanguage == 'AR' ? 'rtlText': '']">
    <div class="page-banner">
      <h1 v-html="pageTitle"></h1>
    </div>
    <div class="page-content">
      <div class="page-content__inner">
        <article class="article">
          <div v-html="content"></div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import store from '../store';

import { helpers } from '../helper/portal_config';

const apiMethod = 'getPortalTermsOfService';

export default {
  name: 'Terms',
  title: helpers.portal_name,
  data() {
    return {
      pageTitle: this.$i18n.t('terms'),
      content: '',
      currentLanguage: "",
      currentCountry: ""
    };
  },
  methods: {
    setCurrentCountry() {
      const { host } = window.location;
      this.currentCountry = host.split('.')[0].slice(0,2).toUpperCase();
    },
    setCurrentLanguage() {
      var lang = "";
      const { host } = window.location;
      var country = host.split('.')[0].slice(0,2).toUpperCase();
      if (helpers.countryDefaultLanguage.hasOwnProperty(country)) {
        lang = helpers.countryDefaultLanguage[country].toUpperCase();
      } else {
        lang = country.toUpperCase();
      }
      this.currentLanguage = lang;
    },
  },
  created() {
    this.setCurrentLanguage();
    this.setCurrentCountry();

    axios
      .get(
        `https://api.hubdelivery.io/store/${apiMethod}?portal_name=${
          helpers.portal_name
        }&country=${this.currentCountry}&language=${this.currentLanguage}&text_version=${this.$route.params.termsversion}`,
      )
      .then((response) => {
        if (response.data.treatment === 1) {
          const str = response.data.content;
          const stringData = str.replace(/\\n/g, '<br />');
          this.content = stringData;
        }
      })
      .catch((error) => {
        console.error(error.response);
      });
  },
};
</script>
