var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section__inner section__inner--noRightpad"},[_c('div',{staticClass:"section-head"},[_c('h2',{staticClass:"section-head__title",class:[this.currentLanguage == 'AR' ? 'rtlText': '']},[_vm._v(" "+_vm._s(_vm.$t("product.relateditems.title"))+" ")])]),_c('div',{staticClass:"section-body"},[_c('swiper',{staticClass:"swiper carousel-wrapper carousel-wrapper--smpad",attrs:{"options":_vm.swiperOption,"auto-update":true,"auto-destroy":false,"delete-instance-on-destroy":true,"cleanup-styles-on-destroy":true}},_vm._l((_vm.products),function(ritem){return _c('swiper-slide',{key:ritem.product_id,class:_vm.isBookType
            ? 'carousel-item card--v4 card--ebook'
            : 'carousel-item card--v4'},[_c('div',{staticClass:"item-img"},[_c('router-link',{attrs:{"to":{
              name: 'ProductDetails',
              params: { productId: ritem.product_id, type: _vm.helpers.portal_name  },
            }}},[_c('img',{attrs:{"src":ritem.icon_small,"alt":"Product Name"}})])],1),_c('div',{staticClass:"item-data"},[_c('div',{staticClass:"data-value"},[_c('router-link',{attrs:{"to":{
                name: 'ProductDetails',
                params: { productId: ritem.product_id, type: _vm.helpers.portal_name },
              }}},[_c('h3',[_vm._v(_vm._s(ritem.title))])])],1),_c('router-link',{attrs:{"to":{
                name: 'ProductDetails',
                params: {
                    productId: ritem.product_id,
                    type: _vm.helpers.portal_name
                    },
              }}},[_c('div',{staticClass:"btn--primary btn--sm",class:[this.currentLanguage == 'AR' ? 'rtlText': '']},[(ritem.type === 1)?_c('span',[_vm._v(_vm._s(_vm.$t("button.playGame")))]):(ritem.type === 5)?_c('span',[_vm._v(_vm._s(_vm.$t("button.playVideo")))]):_c('span',[_vm._v(_vm._s(_vm.$t("button.download")))])])])],1)])}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }