<template>
  <div>
    <div class="page-banner">
      <h1 v-html="pageTitle"></h1>
    </div>
    <div class="page-content">
      <div class="page-content__inner">
        <article class="article">
          <p style="text-align: center">{{ $t("404.title") }}</p>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  title: 404,
  data() {
    return {
      pageTitle: '404',
      content: '',
    };
  },
};
</script>
