<template>
  <div class="search">
    <form method="GET" class="form--search" @submit.prevent="submitSearch">
       <button type="button" class="search-toggle" @click="searchToggle">
            <svg
            class="search-icon"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
          >
            <path
              d="M31.008 27.231l-7.58-6.447c-0.784-0.705-1.622-1.029-2.299-0.998 1.789-2.096 2.87-4.815 2.87-7.787 0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12c2.972 0 5.691-1.081 7.787-2.87-0.031 0.677 0.293 1.515 0.998 2.299l6.447 7.58c1.104 1.226 2.907 1.33 4.007 0.23s0.997-2.903-0.23-4.007zM12 20c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z"
            ></path>
          </svg>
    </button>
      <div class="input-wrapper">
        <button :color="helpers.accent_color" class="search__btn" type="submit">
          <svg
            class="search-icon"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
          >
            <path
              d="M31.008 27.231l-7.58-6.447c-0.784-0.705-1.622-1.029-2.299-0.998 1.789-2.096 2.87-4.815 2.87-7.787 0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12c2.972 0 5.691-1.081 7.787-2.87-0.031 0.677 0.293 1.515 0.998 2.299l6.447 7.58c1.104 1.226 2.907 1.33 4.007 0.23s0.997-2.903-0.23-4.007zM12 20c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z"
            ></path>
          </svg>
        </button>
        <input
          type="text"  :class="[this.currentLanguage == 'AR' ? 'rtlText': '']"
          name="search-q"
          :placeholder="$t('search.placeholder')"
          v-model="query"
        />
        <button @click="closeSearch" class="btn--search-close">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
            <path d="M31.708 25.708c-0-0-0-0-0-0l-9.708-9.708 9.708-9.708c0-0 0-0 0-0 0.105-0.105 0.18-0.227 0.229-0.357 0.133-0.356 0.057-0.771-0.229-1.057l-4.586-4.586c-0.286-0.286-0.702-0.361-1.057-0.229-0.13 0.048-0.252 0.124-0.357 0.228 0 0-0 0-0 0l-9.708 9.708-9.708-9.708c-0-0-0-0-0-0-0.105-0.104-0.227-0.18-0.357-0.228-0.356-0.133-0.771-0.057-1.057 0.229l-4.586 4.586c-0.286 0.286-0.361 0.702-0.229 1.057 0.049 0.13 0.124 0.252 0.229 0.357 0 0 0 0 0 0l9.708 9.708-9.708 9.708c-0 0-0 0-0 0-0.104 0.105-0.18 0.227-0.229 0.357-0.133 0.355-0.057 0.771 0.229 1.057l4.586 4.586c0.286 0.286 0.702 0.361 1.057 0.229 0.13-0.049 0.252-0.124 0.357-0.229 0-0 0-0 0-0l9.708-9.708 9.708 9.708c0 0 0 0 0 0 0.105 0.105 0.227 0.18 0.357 0.229 0.356 0.133 0.771 0.057 1.057-0.229l4.586-4.586c0.286-0.286 0.362-0.702 0.229-1.057-0.049-0.13-0.124-0.252-0.229-0.357z"></path>
            </svg>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from "axios";
import { helpers } from "../helper/portal_config";
import EventBus from '../helper/event_bus';

export default {
  name: "Search",
  data() {
    return {
      query: "",
    };
  },
  methods: {
    submitSearch() {
    let innerWrapper = document.querySelector('.form--search .input-wrapper');
    innerWrapper.style.display = "none";

      if (this.query.length > 0) {
        this.$store.dispatch('globals/getSearchResults', {
            q: this.query,
            api: helpers.api_Search,
            portalName: helpers.portal_name,
            country: this.currentCountry,
            language: this.currentLanguage,
        }).then(() => {
            this.$router
                .push({ name: "SearchResult", query: { q: this.query } })
                .catch(() => {});
        }).catch(()=>{});
      }
    },
    setCurrentCountry() {
      const { host } = window.location;
      this.currentCountry = host.split('.')[0].slice(0,2).toUpperCase();
    },
    setCurrentLanguage() {
      var lang = "";
      const { host } = window.location;
      var country = host.split('.')[0].slice(0,2).toUpperCase();
      if (helpers.countryDefaultLanguage.hasOwnProperty(country)) {
        lang = helpers.countryDefaultLanguage[country].toUpperCase();
      } else {
        lang = country.toUpperCase();
      }
      this.currentLanguage = lang;
    },
    closeSearch() {
        let innerWrapper = document.querySelector('.form--search .input-wrapper');
        let closeBtn = document.querySelector('.btn--search-close');
        innerWrapper.style.display = "none";
    },
    searchToggle(e) {
        e.stopPropagation();

        let innerWrapper = document.querySelector('.form--search .input-wrapper');
        innerWrapper.style.display = "block";
    }
  },
  created() {
    this.helpers = helpers;
    this.setCurrentLanguage();
    this.setCurrentCountry();
  }
};
</script>

<style scoped>
.search {
  margin-right: 20px;
}

@media (max-width: 667px) {
    .search {
        margin-right: 10px;
    }
}

.search input {
  height: 42px;
  border: none;
  border-radius: 10px;
  padding: 0 50px 0 20px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.15);
  max-width: 220px;
  width: 100%;
  font-size: 15px;
}

@media (max-width: 667px) {
    .search input {
        display: block;
        width: 100%;
        border-radius: 0;
        position: absolute;
        left: 0;
        top: 100%;
        height: 47px;
        max-width: 100%;
        padding-right: 90px;
    }
}

.search input:focus {
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.25);
  outline: none;
}

.input-wrapper {
  position: relative;
}

@media (max-width: 667px) {
    .input-wrapper {
        display: none;
    }
}

@media (min-width: 667px) {
     .input-wrapper {
        display: block !important;
    }
}

.input-wrapper.is-hidden {
    display: none;
}

.search__btn {
  /* background: #ffdd3d; */
  position: absolute;
  top: 0;
  width: 45px;
  height: 100%;
  right: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: all 0.25s ease-in-out;
}

@media (max-width: 667px) {
    .input-wrapper {
        position: static;
    }
    .search__btn {
        background: #fff;
        border-radius: 5px;
        height: 40px;
        position: absolute;
        top: 65px;
        right: 40px;
        z-index: 1000;
    }

    .search__btn.is-active {
        fill: #f6212b;
    }
}

.search .search-icon {
  width: 17px;
  height: 17px;
}

@media (max-width: 667px) {
    .search .search-icon {
        width: 18px;
        height: 18x;
    }
}

.search .search-icon path {
  /* fill: #111; */
  transition: all 0.25s ease-in-out;
}

.search__btn:hover {
  /* background: #111; */
}

.search__btn:hover .search-icon path {
  /* fill: #ffdd3d; */
}

.btn--search-close {
    position: absolute;
    right: 10px;
    top: 75px;
}

.btn--search-close svg {
    width: 17px;
    height: 17px;
    fill: #999;
}

.search-toggle {
    position: relative;
    width: 45px;
    height: 45px;
}

@media (min-width: 667px) {
    .search-toggle {
        display: none;
    }
    .btn--search-close {
        display: none;
    }
}
</style>
