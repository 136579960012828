import Vue from 'vue';
import UUID from 'vue-uuid';
import VueCookies from 'vue-cookies';
import { BootstrapVue } from 'bootstrap-vue';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import App from './App.vue';
import router from './router';
import store from './store';
import { i18n } from './plugins/i18n.js';
import titleMixin from './mixins/titleMixin';
import VuePlyr from 'vue-plyr/dist/vue-plyr.ssr.js';
import 'vue-plyr/dist/vue-plyr.css';

import 'swiper/swiper.scss'; 
//import 'swiper/swiper-bundle.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/styles/style.css';
import './assets/styles/portal.css';

Vue.config.productionTip = false;

Vue.mixin(titleMixin);

Vue.use(BootstrapVue);
Vue.use(VueAwesomeSwiper);
Vue.use(UUID);
Vue.use(VueCookies);

Vue.use(VuePlyr, {
    plyr: {}
})

new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');