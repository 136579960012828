<template>
  <div>
    <div class="page-content">
      <section class="section--products section--categories">
        <div class="section__inner" style="padding-right: 20px">
          <div class="section__box section__box--nobg">
            <h2 class="box-title box-title--category" :class="[this.currentLanguage == 'AR' ? 'rtlText': '']">{{ title }}</h2>
            <div class="category-products">
              <div
                :class="isBookType ? 'card--v4 card--ebook' : 'card--v4'"
                v-for="item in products"
                :key="item.product_id"
              >
                <router-link
                  :event="isLoggedIn ? 'click' : ''"
                  :to="{
                    name: 'ProductDetails',
                    params: {
                      type: helpers.portal_name,
                      productId: item.product_id,
                    },
                  }"
                  @click.native="
                    checkIsLogged({
                      name: 'ProductDetails',
                      params: {
                        type: helpers.portal_name,
                        productId: item.product_id,
                      },
                    })
                  "
                >
                  <div class="item-img">
                    <img :src="item.icon_small" :alt="title" />
                  </div>
                  <div class="item-data">
                    <div class="data-value">
                      <h3 class="data-title">{{ item.title }}</h3>
                      <div class="data-category">
                        {{ item.short_description }}
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import EventBus from '../helper/event_bus';
import { helpers } from '../helper/portal_config';
import RelatedItem from '../components/RelatedItem';

const api = 'getProductListByCategory';

export default {
  name: 'ProductCategory',
  title: helpers.portal_name,
  data() {
    return {
      products: [],
      helpers: '',
      title: '',
      currentLanguage: "",
      currentCountry: ""
    };
  },
  methods: {
    checkIsLogged(link) {
      // if you are not login than showed modal
      if (!this.isLoggedIn) {
        EventBus.$emit('SHOW_SIGNIN_MODAL', link);
      }
    },
    setCurrentCountry() {
      const { host } = window.location;
      this.currentCountry = host.split('.')[0].slice(0,2).toUpperCase();
    },
    setCurrentLanguage() {
      var lang = "";
      const { host } = window.location;
      var country = host.split('.')[0].slice(0,2).toUpperCase();
      if (helpers.countryDefaultLanguage.hasOwnProperty(country)) {
        lang = helpers.countryDefaultLanguage[country].toUpperCase();
      } else {
        lang = country.toUpperCase();
      }
      this.currentLanguage = lang;
    },
  },
  computed: {
    ...mapGetters({
      accessCode: 'globals/accessCode',
      isLoggedIn: 'globals/isLoggedIn',
    }),
    isBookType() {
      return this.helpers.portal_name == 'Carefizz'; // change ebook portal name
    },
  },
  created() {
    this.helpers = helpers;
    // check user was logged and send access_code as a parameter.
    let apiUrl = null;
    this.setCurrentLanguage();
    this.setCurrentCountry();

    if (this.isLoggedIn) {
        apiUrl = `https://api.hubdelivery.io/store/${api}?portal_name=${
          this.$route.params.name || helpers.portal_name
        }&country=${this.currentCountry}&language=${this.currentLanguage}&product_type=${
          helpers.portal_type
        }&product_category=${this.$route.params.id}&access_code=${this.accessCode}`;
    } else {
        apiUrl = `https://api.hubdelivery.io/store/${api}?portal_name=${
          this.$route.params.name || helpers.portal_name
        }&country=${this.currentCountry}&language=${this.currentLanguage}&product_type=${
          helpers.portal_type
        }&product_category=${this.$route.params.id}`;
    }

    axios
      .get(
          apiUrl
      )
      .then((response) => {
        if (response.data.treatment === 1) {
          this.products = response.data.products;
          this.title = this.$route.params.title;
        }
      });
  },
};
</script>

<style scoped>
.card--v4.card--ebook .item-img img {
  object-fit: contain;
  object-position: top;
}

.card--v4.card--ebook .item-img {
  width: 50px;
  height: 90px;
}

@media (min-width: 1200px) {
  .card--v4.card--ebook .item-img {
    width: 90px;
    height: 120px;
  }
}

@media (min-width: 1600px) {
  .card--v4.card--ebook .item-img {
    width: 80px;
    height: 135px;
  }
}
</style>
