<template>
  <div :class="[this.currentLanguage == 'AR' ? 'rtlText': '']">
    <div class="product-entry product-entry--video-type">
      <div class="product-entry__content">
        <div class="video-type__item">
          <div class="img-wrapper">
            <a :href="product.url" class="play-btn" @click.prevent="index = 0">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
              >
                <path
                  d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16-7.163-16-16-16zM16 29c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13zM12 9l12 7-12 7z"
                ></path>
              </svg>
            </a>
            <!-- generated when type product type is 5 -->
            <div v-if="product.type != 5">
                <img :src="product.icon_small" :alt="product.title" />
            </div>
            <div v-else>
                <img class="video-thumb" :src="product.icon_small" :alt="product.title" />
            </div>
          </div>
          <div class="item-data">
            <div class="headline">
              <h2>{{ product.title }}</h2>
              <Stars :product="product" v-if="helpers.hasStars == 1" />
            </div>
            <Favourite :product="product" v-if="helpers.hasFavourite == 1" />
            <a @click="index = 0" class="product-entry__action-btn" :class="[this.currentLanguage == 'AR' ? 'rtlText': '']">
              <span v-if="product.type === 1">{{ $t("button.playGame") }}</span>
              <span v-else-if="product.type === 5">{{ $t("button.playVideo") }}</span>
              <span v-else>{{ $t("button.download") }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <CoolLightBox
      :items="[
        {
          title: product.title,
          description: product.description_short,
          src: product.url,
        },
      ]"
      :index="index"
      @close="index = null"
    >
    </CoolLightBox>
  </div>
</template>

<script>
import { helpers } from "../helper/portal_config";
import Stars from "@/components/Stars";
import Favourite from "@/components/Favourite";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default {
  name: "ProductDetailsVideo",
  data() {
    return {
      index: null,
    };
  },
  props: {
    product: Object,
  },
  components: {
    Stars,
    Favourite,
    CoolLightBox,
  },
  created() {
    this.helpers = helpers;
  },
};
</script>

<style scoped>
.video-thumb {
    object-fit: contain;
    width: 100% !important;
    object-position: 40px 18%;
}
</style>
